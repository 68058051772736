'use client'

import { useEffect } from 'react'

// This is a hack to fix an issue with the link plugin of TinyMCE
// if you use TinyMCE in a modal, then the fields of the link plugin become uneditable
// fix taken from here: https://stackoverflow.com/a/78385086
//other sources:
// https://stackoverflow.com/questions/58912965/tinymce-5-1-1-insert-link-form-fields-disabled

const fix = (event: FocusEvent) => {
  if (
    event.target instanceof HTMLElement &&
    event.target.closest(
      '.tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root'
    ) !== null
  ) {
    event.stopImmediatePropagation()
  }
}

export function FixTinyMCELinkPlugin() {
  useEffect(() => {
    document.addEventListener('focusin', fix)
    return () => {
      document.removeEventListener('focusin', fix)
    }
  }, [])

  return null
}
