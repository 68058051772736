import { Roboto } from 'next/font/google'

export const colors = {
  background: {
    paper: '#ffffff',
    default: 'rgb(238, 242, 246)',
  },

  primary: {
    dark: '#303F9F',
    main: '#3F51B5',
    light: '#C5CAE9',
  },

  secondary: {
    dark: '#435761',
    main: '#607D8B',
    light: '#7F97A2',
  },

  success: {
    light: '#b9f6ca',
    200: '#69f0ae',
    main: '#00e676',
    dark: '#00c853',
  },
  error: {
    light: '#ef9a9a',
    main: '#f44336',
    dark: '#c62828',
  },
  orange: {
    light: '#fbe9e7',
    main: '#ffab91',
    dark: '#d84315',
  },

  warning: {
    light: '#fff8e1',
    main: '#ffe57f',
    dark: '#ffc107',
  },

  grey: {
    50: '#f8fafc',
    100: '#eef2f6',
    200: '#e3e8ef',
    300: '#cdd5df',
    500: '#697586',
    600: '#4b5565',
    700: '#364152',
    900: '#121926',
  },
}

const font = Roboto({
  subsets: ['latin'],
  display: 'swap',
  weight: ['100', '300', '400', '500', '700', '900'],
})

export const config = {
  colors,
  heading: colors.grey[900],
  paper: colors.background.paper,
  backgroundDefault: colors.background.paper,
  background: colors.primary.light,
  darkTextPrimary: colors.grey[700],
  darkTextSecondary: colors.grey[500],
  textDark: colors.grey[900],
  menuSelected: colors.secondary.dark,
  menuSelectedBack: colors.secondary.light,
  divider: colors.grey[200],
  fontFamily: font.style.fontFamily,
  borderRadius: 6,
}

export type ThemeConfig = typeof config
