import throttle from 'lodash/throttle'

const frontendPushApiKey =
  process.env.NEXT_PUBLIC_APPSIGNAL_FRONTEND_PUSH_API_KEY ?? ''

interface AppSignalWebVital {
  name: string
  value: number
  metricType: string
  tags: Record<string, string>
}

export interface NextWebVital {
  name: string
  value: number
  rating: 'good' | 'needs-improvement' | 'poor'
  navigationType: string
}

let collectedMetrics: AppSignalWebVital[] = []

const ratingMap = {
  good: 1,
  'needs-improvement': 0,
  poor: -1,
}

const sendMetrics = () => {
  const body = JSON.stringify(collectedMetrics)
  const url = `https://appsignal-endpoint.net/metrics/json?api_key=${frontendPushApiKey}`

  function sendFallback() {
    fetch(url, {
      body,
      method: 'POST',
      keepalive: true,
      headers: {
        'Content-Type': 'application/json',
      },
      // eslint-disable-next-line -- allow to avoid polluting logs
    }).catch(console.error)
  }

  try {
    navigator.sendBeacon(url, body)
  } catch (err) {
    sendFallback()
  }

  collectedMetrics = []
}

const throttledSendMetrics = throttle(sendMetrics, 1000)

export const reportWebVitals = (metric: NextWebVital, page: string) => {
  if (!frontendPushApiKey) {
    // eslint-disable-next-line -- allow warning
    console.warn('Skipping reporting of Web Vitals. API key is missing')
    return
  }

  ;[{}, { page }, { page, navigationType: metric.navigationType }].forEach(
    (tags) => {
      collectedMetrics.push({
        name: `web_vitals.${metric.name.toLowerCase()}.rating`,
        metricType: 'gauge',
        value: ratingMap[metric.rating],
        tags: tags as Record<string, string>,
      })
      collectedMetrics.push({
        name: `web_vitals.${metric.name.toLowerCase()}.value`,
        metricType: 'histogram',
        value: metric.value,
        tags: tags as Record<string, string>,
      })
    }
  )

  throttledSendMetrics()
}
