'use client'

import type { ReactNode } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { theme } from './theme'

export function ThemeProvider({
  children,
}: {
  children: ReactNode
}): JSX.Element {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {/** This was added to make sure that  Googles' Drive picker would always
      show up above any other element of the page */}
      <style>{`
          body .picker-dialog-bg {
            z-index: 1000000 !important;
          }
          body .picker-dialog {
            z-index: 1000001 !important;
          }
        `}</style>
      {children}
    </MuiThemeProvider>
  )
}
